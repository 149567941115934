import React from 'react'

import mainIcon from '../../assets/images/icons/icon-lg-CMMC.png'
import icon1 from '../../assets/images/icons/icon-plan.png'
import icon2 from '../../assets/images/icons/icon-scorecards.png'
import icon3 from '../../assets/images/icons/icon-milestones.png'
import icon4 from '../../assets/images/icons/icon-audit.png'
import process from '../../assets/images/services/process-line.png'
import iconcheck from '../../assets/images/services/icon-check-process.png'

import {Link} from 'gatsby'



const CMMC = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="row">
                            <div className="d-flex col-lg-4 justify-content-center align-items-start">
                                <img src={mainIcon} alt="SSAE18 (SOC 2)" style={{maxWidth: '300px'}} />
                            </div>
                            <div className="col-lg-8">
                                <h1>Cybersecurity Maturity Model Certification (CMMC)</h1>
                                <p>The Department of Defense initiated the CMMC security program and took effect in September 2020. The CMMC certification is required by  organizations supporting DoD contracts, or in some cases by organizations that provide services to defense contractors. Even organizations that  do not deal directly with the DoD may be asked by customers to comply with CMMC to help support their certification.</p>
                            </div>
                        </div>
                    <hr className="mtb-70"/>
                       <h1 className="subheading pb-4">What is CMMC?</h1>
                       <div className="col-lg-8 offset-lg-2">
                        <p>CMMC outlines a set of controls (capabilities) and practices that are designed to ensure companies are applying adequate controls for the  protection of two data categories:</p>
                       <ol>
                            <li> Controlled Unclassified Information (CUI) - Information that requires certain protections that is labelled CUI by the DoD and provided to  organizations to support contracts and projects</li>
                            <li> Federal Contract Information (FCI) - Information that is provided or generated under a government contract that helps support products  or services provided to the government</li>
                       </ol>
                       </div>

                       <h1 className="subheading pb-4">CMMC Maturity Levels</h1>
                       <div className="col-lg-8 offset-lg-2">
                        <p>CMMC sets forth five maturity levels that require increasing numbers of controls and practices, depending on the type of data being processed,  or requirements stated within the contract.</p>
                        <p>Level 1 is the lowest level of maturity, contained only 17 practices, with Level 5 requiring 171 practices </p>
                       </div>
                       <h1 className="pt-70">The CMMC module helps get you ready for CMMC certification </h1>
                   
                         <h4 className="text-center ptb-40 ">Tools and Templates built on Atlassian</h4>
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="single-solutions-box">
                                <div className="icon">
                                    <img src={icon1} style={{width: 120}} alt="Document Templates" />
                                    </div> 
                                    <p>System Security Plan (SSP) template</p> 
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6">
                                <div className="single-solutions-box">
                                <div className="icon">
                                    <img src={icon2} style={{width: 120}} alt="Risk Assessment" />
                                </div>
                                    <p>CMMC Control Scorecards</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-sm-6 offset-lg-0 ">
                                <div className="single-solutions-box">
                                    <div className="icon">
                                        <img src={icon3} style={{width: 120}} alt="Control Scorecards" />
                                    </div>
                                    <p>Plan of Action Milestones (POAM)</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 offset-lg-0 ">
                                <div className="single-solutions-box">
                                    <div className="icon">
                                        <img src={icon4}  style={{width: 120}}alt="Audit Program" />
                                    </div>
                                    <p>Audit program</p>
                                </div>
                            </div>
                        </div>
                        <p className="text-center col-lg-6 offset-lg-3">Agility is pre-configured with all the tools and templates you need to implement your CMMC program.</p>
                        <p className="text-center col-lg-6 offset-lg-3">Easily track program status, control compliance, document status and remediation all within your or Ekko hosted Atlassian
Cloud instance.</p>
                        <div className="process-rule">
                            <img src={process} className="mtb-70" alt="process" />
                        </div>
                        {/* <h1 className="mtb-70 text-center" >Start your SOC 2 Program </h1> */}
                        <div className="row">
                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Start your CMMC Program 
                                    </h3>
                                    <p className="step-subtitle">
                                       Agility provides you everything you need to start your CMMC program
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Scope guidance and templates
                                        </li>
                                        <li>
                                            Defined, roadmap project plan and milestones 
                                        </li>
                                        <li>
                                            Control remediation dashboards and reports  
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Create Information Security Policies & Procedures 
                                    </h3>
                                    <p className="step-subtitle">
                                       Pre-built CMMC compliant policy templates  
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                           All policies required to support CMMC domains
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" />Gap Analysis and Scorecard
                                    </h3>
                                    <p className="step-subtitle">
                                        Establish your control baseline and program starting point 
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Generate control scorecard and reports
                                        </li>
                                        <li>
                                            Define your remediation and implementation roadmap  
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" />Select Certification C3PAO firm
                                    </h3>
                                    <p className="step-subtitle">
                                        CMMC requires an external C3PAO to conduct your certification audit 
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            CMMC Readiness assessment 
                                        </li>
                                        <li>
                                          Level 1-5 Certification assessment
                                        </li>

                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Program Implementation
                                    </h3>
                                    <p className="step-subtitle">
                                        Agility provides you all the tools to implement, track and remediate your CMMC Information Security program   
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Remediation and control improvement task tracking 
                                        </li>
                                        <li>
                                          Reporting and dashboards 
                                        </li>
                                        <li>
                                          Reminders and scheduling 
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> Internal Readiness
                                    </h3>
                                    <p className="step-subtitle">
                                       Conduct your Internal readiness assessment inside Agility
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                           CMMC readiness assessment
                                        </li>
                                        <li>
                                            Pre-built audit test cases
                                        </li>
                                        <li>
                                            Generate and manage control evidence
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" />Certification
                                    </h3>
                                    <p className="step-subtitle">
                                       Conduct your CMMC certification audit with your selected registrar audit firm
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Get certified
                                        </li>
                                        <li>
                                           Conduct annual audits 
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-6 p-4">
                                <div className="step-panel">
                                    <h3 className="step-title">
                                    <img src={iconcheck} className="check-icon" alt="icon" /> CMMC Maintenance
                                    </h3>
                                    <p className="step-subtitle">
                                       Agility helps you maintain your ISMS
                                    </p>
                                    <ul className="step-sublist">
                                        <li>
                                            Pre-built task templates
                                        </li>
                                        <li>
                                           Schedule and track ongoing activities
                                        </li>
                                         <li>
                                           POAM tracking and CONMON process
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 mt-50"><h3 className="subheading">For more information about CMMC</h3></div>
                            <div className="center-button"> 
                                     <Link 
                                    to="/cmmc-details" 
                                    className="default-btn"
                                >
                                  Read More
                                </Link>
                            </div>
                          
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default CMMC